// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resources-js": () => import("../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-students-js": () => import("../src/pages/students.js" /* webpackChunkName: "component---src-pages-students-js" */),
  "component---src-pages-teachers-js": () => import("../src/pages/teachers.js" /* webpackChunkName: "component---src-pages-teachers-js" */)
}

